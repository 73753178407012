<template>
  <full-page-gradient class="credit-check-failed" color="blue">
    <!-- Credit check failed header -->
    <mobile-full-page-wrapper>
      <v-layout justify-center class="no-grow">
        <v-flex xs11>
          <typography type="h3">Thank you for applying.</typography>
        </v-flex>
      </v-layout>
      <v-layout class="no-grow" justify-center mb-5>
        <v-flex xs10 md9 lg8>
          <typography :type="$vuetify.breakpoint.xsOnly ? 'body' : 'h5'" light>
            Unfortunately, your application falls outside our current credit
            criteria and we are unable to open a {{ brand }} account at this
            time.
          </typography>
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>

    <!-- Failed credit check reasons -->
    <v-layout v-if="$vuetify.breakpoint.smAndUp" justify-center mb-5>
      <v-flex sm9 md8 lg6>
        <v-layout>
          <!-- Left column -->
          <v-flex sm6 pr-3>
            <typography type="small-p" align="left">
              Usually this happens because our credit checks indicate one or
              more adverse credit records against you or your business.
            </typography>
          </v-flex>
          <!-- Right column -->
          <v-flex sm6 pl-3>
            <typography type="small-p" align="left">
              If you’d like to speak to someone about your credit record, you
              can call our credit reporter Dunn and Bradstreet on
              <a href="tel:0800 362 222">0800 362 222.</a>
            </typography>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>

    <mobile-full-page-wrapper>
      <v-layout justify-center>
        <v-flex class="no-grow">
          <!-- Back to home button -->
          <v-layout justify-center>
            <v-flex xs10 sm6>
              <round-button
                color="white"
                class="home-button"
                @click="onHomeClick"
                >Back to home</round-button
              >
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </mobile-full-page-wrapper>
  </full-page-gradient>
</template>

<script>
import FullPageGradient from '@/components/templates/FullPageGradient.vue';
import Typography from '@/components/atoms/Typography.vue';
import RoundButton from '@/components/atoms/RoundButton.vue';
import MobileFullPageWrapper from '@/components/molecules/MobileFullPageWrapper.vue';
import { HOME } from '@/constants/routes';
import { BRAND } from '@/constants/form';

export default {
  components: {
    Typography,
    FullPageGradient,
    RoundButton,
    MobileFullPageWrapper
  },
  data() {
    return {
      brand: BRAND
    };
  },
  methods: {
    onHomeClick() {
      window.location.href = HOME;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_responsive.scss';
@import '@/assets/styles/_variables.scss';
@import '@/assets/styles/_mixins.scss';

.no-grow {
  flex-grow: 0;
}

.credit-check-failed {
  .home-button {
    color: $orange;
  }
}

.offer {
  color: $orange;
}

.offer-title {
  color: #7771ce;
}

.reasons {
  text-align: left;
}
</style>
